import React from "react"

import Layout from "../components/layout"
import { Subfooter } from "../components/utils"
import {
  Stories,
  FAQ,
  Packages,
  PricingTable,
  SubClubs,
} from "../components/join"

import SEO from "../components/seo"

const JoinUs = () => {
  return (
    <Layout>
      <SEO title="Join us" />
      {/* <PageHeader color="blue" heading="The Green Investment Club is right for you if you are... "
            paragraph=" " /> */}
      <Stories />
      <Packages />
      <PricingTable />
      <SubClubs />
      <FAQ />
      <Subfooter text="All clear? Ready to get started?" />
    </Layout>
  )
}

export default JoinUs
