import React from "react"
import { Button } from "../../utils"

const Package = ({ title, description, price, color, perAnnum = true }) => (
  <article
    className={
      "px-10 py-14 text-blue-300 md flex  flex-col sm:rounded-lg bg-" + color
    }
  >
    <header>
      <h2>{title}</h2>
    </header>
    <content className="block sm:h-54 ">
      <p>{description}</p>
    </content>
    <footer>
      <a
        aria-label="link"
        href="https://webapp.thegreeninvestmentclub.com/users/signup"
      >
        <Button text={price} color="torq-300" />
      </a>
      <p
        className={
          (!perAnnum && "hidden ") +
          `sm:pl-4 font-display text-lg pt-2 font-medium mb-0`
        }
      >
        <span className="line-through">per annum</span>{" "}
        <span className="font-bold">for life</span>
      </p>
    </footer>
  </article>
)

export default Package
