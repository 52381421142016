import React from 'react'
// import { useState } from 'react'
import PlusIcon from "../../../images/icons/plus-icon.svg"

import Fade from 'react-reveal/Fade'

const FaqItem = ({title, content, currentTab, index, switchTab }) => {

    // console.log(currentTab)
    return(
            <article className="bg-white rounded-lg sm:px-8 px-4 sm:pt-8 pt-4 pb-2 max-w-screen-lg mx-auto mb-6 ">
                <header className="flex justify-between outline-none items-start cursor-pointer" onClick={()=> switchTab()} role="button" tabIndex="0" onKeyDown={()=> switchTab()} >
                    <h3 className="text-torq-200 sm:text-xl text-base font-display font-medium ">{title}</h3>
                <img src={PlusIcon}  className={(currentTab === index ? "transform rotate-45 sm:w-auto  ":"")+`inline-block transition w-6 pl-2`}  alt="plus icon"/>
                </header>
                <Fade collapse when={currentTab === index }>
                    <p className={`sm:pl-12 pl-6 w-11/12`}>{content}</p>
                </Fade>
            </article>
        )
}

export default FaqItem