import React from 'react'
import Story from './story'

import Wunmi from "../../../images/illustrations/wunmi.webp"
import Chidozie from "../../../images/illustrations/chidozie.webp"
import Victoria from "../../../images/illustrations/victoria.webp"
import Hakeem from "../../../images/illustrations/hakeem.webp"

import Fade from 'react-reveal/Fade'

export const Stories = () => {
    return(
        <section className="sm:py-32 py-16 px-8 bg-torq-100 overflow-hidden">
            <Fade bottom>
                <h1 className="max-w-screen-md mx-auto mb-32 sm:text-center px-4 text-torq-200">
                    The Green Investment Club is right for you if you are...
                </h1>
            </Fade>

            {/* Wunmi */}
            <Story 
            prefix="like" 
            name="Wunmi" 
            text="open to admitting that some of the most important things you want to achieve in life depend on the income you earn and your current income will not get you there." 
            image={Wunmi} />
            
            {/* Chidozie */}
            <Story 
            prefix="or like" 
            reverse={true} 
            name="Chidozie" 
            text="focused on the long term and you understand there will be ups and downs, but you can make huge gains by investing smarter." 
            image={Chidozie} />

            {/* Victoria */}
            <Story 
            prefix="or" 
            name="Victoria" 
            text="with absolutely no problem saving money, but with no clue at all on how to invest or put it to work correctly." 
            image={Victoria} />

            {/* Hakeem */}
            <Story 
            prefix="or maybe like" 
            reverse={true} 
            name="Hakeem" 
            text="you want to earn passive income and stop earning income only based on the hours you work but you're unsure how to get started." 
            image={Hakeem} />
        </section>
    )
}

