import React from 'react'
import Fade from 'react-reveal/Fade'

const Story = ({image, name, text, prefix, reverse }) => (
    <Fade left cascade>

    <article className={(reverse ? "sm:flex-row-reverse ":"") + `flex sm:items-center my-12   justify-between max-w-screen-md mx-auto`}>
        <header className="sm:w-4/12 w-5/12 ">
            <img src={image} className="w-full " alt={name} />
        </header>
        <content className="w-7/12 ">
            <p className=" sm:text-4xl text-torq-200">
                ...{prefix} {name}
            </p>
            <p className="sm:-mt-0 -mt-4">{text}</p>
        </content>
        {/* <div className="w-1/12"></div> */}
    </article>
    </Fade>
)

export default Story