import React from "react"
import Mark from "../../../images/icons/mark.svg"

const FeatureMark = ({ mark, active = true }) => {
  return (
    <>
      <div className="w-1/12 "></div>
      <div
        className={
          (active ? "sm:flex hidden " : "flex") +
          ` sm:w-3/12 w-4/12  items-center justify-center`
        }
      >
        {mark && <img src={Mark} alt="available" className="mb-0 w-6" />}
      </div>
    </>
  )
}

export default FeatureMark
