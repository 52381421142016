import React from "react"
import FeatureMark from "./feature-mark"
import Fade from "react-reveal/Fade"
const Row = ({ value, low = false, high = true, active }) => {
  return (
    <Fade top>
      <div className="bg-torq-100 flex max-w-screen-lg mx-auto sm:rounded py-4 mb-4">
        <div className="w-1/12 "></div>
        <div className="sm:w-3/12 w-6/12">{value}</div>
        <FeatureMark mark={low} active={!active} />

        <FeatureMark mark={high} active={active} />
      </div>
    </Fade>
  )
}

export default Row
