import React from "react"
import Package from "./package"
import { Button } from "../../utils"
import { graphql, useStaticQuery } from "gatsby"

const Heading = ({ footer = false, currentPrice, setCurrentPrice }) => {
  const prices = useStaticQuery(
    graphql`
      query {
        allContentfulPricing(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              price
            }
          }
        }
      }
    `
  )

  const the_prices = prices.allContentfulPricing.edges
  const house_member_price = the_prices[1].node.price
  const blackhouse_member_price = the_prices[2].node.price

  return (
    <header className=" max-w-screen-lg sm:mb-8 mb-0  my-6 mx-auto">
      <div
        className={
          (footer ? "hidden " : "flex") +
          ` sm:flex sm:justify-between justify-around px-4 `
        }
      >
        <div className="w-4/12 sm:block hidden"></div>
        <Package
          title={
            <>
              TGIC House <br className="sm:hidden" />
              Member{" "}
            </>
          }
          price={house_member_price}
          currentPrice={currentPrice}
          setPrice={() => setCurrentPrice(house_member_price)}
        />
        {/* <Package title="Community Benefits Maximum Plan" price="285" /> */}
        <Package
          title={
            <>
              TGIC Black <br className="sm:hidden" />
              Member{" "}
            </>
          }
          price={blackhouse_member_price}
          currentPrice={currentPrice}
          setPrice={() => setCurrentPrice(blackhouse_member_price)}
        />
      </div>
      <div
        className={
          (!footer && "shadow-pricing ") +
          " sm:hidden text-center  py-6 bg-white"
        }
      >
        <h3 className="font-display">${currentPrice}</h3>
        <Button text="Subscribe" />
      </div>
    </header>
  )
}

export default Heading
