import React from 'react'
import { Button } from '../../utils'

const Club = ({color, shadow, icon, title, description}) => {
    return(
        <article className={`bg-${color} ${shadow} max-w-screen-md flex sm:flex-row flex-col mx-auto my-12 sm:p-12 p-8 rounded-lg`}>
            <div className="sm:w-1/12 text-center sm:pt-14  pt-0 pb-8">
                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5689 0H10.2968C8.30177 3.64988 4.44048 6.13696 0 6.13696V12.8876C8.23741 12.9199 15.2199 7.49355 17.5689 0Z" fill={icon}/>
                <path d="M17.5689 0H10.2968C8.30177 3.64988 4.44048 6.13696 0 6.13696V12.8876C8.23741 12.9199 15.2199 7.49355 17.5689 0Z" stroke="white"/>
                </svg>

            </div>
            <div className="sm:w-10/12">
            <header>
                <h3 className=" font-display">{title}</h3>
            </header>
            <content>
                <p>
                    {description}
                </p>
            </content>
            <footer className="mt-4">
                <Button text="Get More Info" />
            </footer>
            </div>
        </article>
    )
}

export default Club