import React from 'react'
import Club from './club'

export const SubClubs = () => {
    return(
        <section className="pt-16 pb-32 mx-auto max-w-screen-xl px-6">
            <h2 className="text-center font-display mb-12">Introducing our <br className="sm:hidden"/>new sub-clubs</h2>
            <Club 
            title="Stock Market Club" 
            description="In a busy world filled with stock trading apps and little recommendations on what stocks to add to your personal investment portfolio, we bridge the gap with the stock market club. We understand that a random stock selection process is not the way to beat the market so we work with our members to curate long term portfolios that maximize earnings in both the Nigerian and US stock market. As a member of this club, you’ll get access to extensive research, insightful stock recommendations,  stock calls and knowledgeable facilitators."
            icon="#FFAA06"
            color="yellow"
            shadow="shadow"
            />
            <Club 
            title="Venture Capital Club" 
            description="Interested in investing in high growth start-ups across Africa? You should join this niche club as we partner with the top venture capital funds such as Future Africa, Lofty Inc and Get Funded Africa to educate our members on VC investing and pool funds to invest. Our mission is to provide funding for relevant companies powering economic growth and abundance in Africa."
            icon="#FF4206"
            color="pink"
            shadow="shadow-pink"
            />
        </section>
    )
}