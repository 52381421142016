import React, {useState} from 'react'
import FaqItem from "./item"
import {graphql, useStaticQuery} from 'gatsby'

export const FAQ = () => {

  const [currentTab, SetCurrentTab] = useState(0)

 
    const faqs = useStaticQuery(graphql`
    query {
        allContentfulFaq {
          edges {
            node {
              question
              answer {
                answer
              }
            }
          }
        }
      }
      
    `)
    const allFaq = faqs.allContentfulFaq.edges.map((faq,i) => <FaqItem key={i}
        title={faq.node.question}
        content={faq.node.answer.answer}
        index = {i}
        currentTab={currentTab}
        switchTab = {()=>SetCurrentTab(i)}
        />)
    return(
        <section className="bg-torq-100 sm:py-32 py-16 px-4">
            <h2 className="text-center mb-16">You probably have a few questions</h2>

            {allFaq}
        </section>
    )
}
