import React from "react"
import { Button } from "../../utils"

const Package = ({ title, price, currentPrice, setPrice }) => {
  return (
    <>
      <div className="w-1/12 sm:block hidden"></div>
      <div
        onClick={() => setPrice()}
        onKeyDown={() => setPrice()}
        role="button"
        tabIndex="0"
        className={
          (currentPrice === price
            ? "shadow-pricing price-table-header relative "
            : "") +
          `sm:shadow-none sm:w-3/12 outline-none w-5/12  rounded-t-md  sm:pt-0 pt-4 text-center`
        }
      >
        <p className="mb-2">{title}</p>
        <h3 className="font-display mb-2 sm:block hidden">${price}</h3>
        <span className="sm:block hidden">
          <a
            aria-label="link"
            href="https://webapp.thegreeninvestmentclub.com/users/signup"
          >
            <Button text="Subscribe" />
          </a>
        </span>
      </div>
    </>
  )
}

export default Package
