import React, { useState } from "react"
import Row from "./row"
import Heading from "./heading"
import { graphql, useStaticQuery } from "gatsby"
export const PricingTable = () => {
  const prices = useStaticQuery(
    graphql`
      query {
        allContentfulPricing(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              price
            }
          }
        }
      }
    `
  )

  // Get the price for house member
  const house_member_price = prices.allContentfulPricing.edges[1].node.price

  const [currentPrice, setCurrentPrice] = useState(house_member_price)

  const rows = [
    {
      text: "Full club curriculum of courses",
      high: "",
    },
    {
      text: "Access to recordings from past events/webinars",
      high: "",
    },
    {
      text: "Access to our digital investment tool—Twelvevest",
      low: "",
    },
    {
      text: (
        <>
          Weekly newsletters with actionable tips
          <p className="text-xs mt-1">
            (Our members tell us access to this alone is worth more than the
            cost of joining the club).
          </p>
        </>
      ),
      low: "",
    },
    {
      text: "Standby support from team and other community members",
      low: "",
    },
    {
      text: "Access to webinars",
      low: "",
    },
    {
      text: "Discounted tickets Annual TGIC Hangout Series",
      low: "",
    },
    {
      text: "Access to club’s partners and experts",
      low: "",
    },
    {
      text: "Access to newsletters archive (from inception)",
      low: "",
    },
  ]

  const showRows = rows.map((row, i) => (
    <Row
      key={i}
      value={row.text}
      // medium={row.hasOwnProperty("medium")}
      low={row.hasOwnProperty("low")}
      active={currentPrice === house_member_price}
    />
  ))
  return (
    <section className="sm:py-32 py-16 sm:px-12">
      <Heading
        currentPrice={currentPrice}
        setCurrentPrice={price => setCurrentPrice(price)}
        prices={prices}
      />

      {showRows}
      <Heading currentPrice={currentPrice} footer={true} />
    </section>
  )
}
