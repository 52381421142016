import React from "react"
import Package from "./package"
import { graphql, useStaticQuery } from "gatsby"

export const Packages = () => {
  const prices = useStaticQuery(
    graphql`
      query {
        allContentfulPricing(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              price
            }
          }
        }
      }
    `
  )

  const the_prices = prices.allContentfulPricing.edges
  return (
    <section
      id="packages"
      className="grid max-w-screen-lg mx-auto md:gap-12 gap-4 md:grid-cols-2 grid-cols-1 sm:py-16 py-0"
    >
      <Package
        title={
          <>
            Get the <br className="sm:block hidden" />
            total package
          </>
        }
        description="The Black Member Plan gives you a solid foundation upon which you can build wealth and take control of your finances. With access to our repository of video lessons that teach how to invest correctly, credible opportunities and actionable tips and more, you have the education you need, the investments to choose from and expert advice where you need help."
        price={`SUBSCRIBE ($${the_prices[2].node.price})`}
        color="yellow"
      />
      <Package
        title={
          <>
            Start <br className="sm:block hidden" />
            somewhere
          </>
        }
        description="Stop waiting, join the house to get your investment journey started one week at a time. On the house member plan, you’ll benefit from the basic weekly investment opportunities that help you build up confidence towards achieving your financial goals. You can always upgrade once you’re ready to level up on those financial goals."
        price={`SUBSCRIBE ($${the_prices[1].node.price})`}
        color="pink"
      />
      {/* <Package
        title={
          <>
            Join in full, <br className="sm:block hidden" />
            pay in parts
          </>
        }
        description="Want the full package on the black member plan but can’t afford a one-time payment? Get access to our members-only portal one stack at a time. Each stack represents a module of valuable video lessons and resources you need on your investment journey. Pay for one stack at $120 each at your own pace to attain full membership and access to all our amazing benefits."
        price={`Buy first stack ($${the_prices[0].node.price})`}
        color="torq-100"
        perAnnum={false}
      /> */}
    </section>
  )
}
